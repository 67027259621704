












































import { useMinByDate } from "@/hooks";
import MNotification from "@/mixins/MNotification.vue";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import {
  AssetTransferResponseDto,
  ReceiveAssetTransferRequestDto,
} from "@/models/interface/asset-transfer";
import { assetTransferService } from "@/services/asset-transfer.service";
import { FormModel } from "ant-design-vue";
import moment, { Moment } from "moment";
import { Component, Mixins, Prop, Ref } from "vue-property-decorator";

@Component
export default class ReceiveAssetTransferModal extends Mixins(MNotification) {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;

  @Prop({ type: Boolean, required: false, default: false })
  visible!: boolean;

  @Prop({ type: Object, required: true })
  assetTransferResponseDTO!: AssetTransferResponseDto;

  @Ref("formModel")
  formModel!: FormModel;

  formData: ReceiveAssetTransferRequestDto = {
    receiveBy: "",
    receiveDate: "",
  };

  formRules = {
    receiveBy: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    receiveDate: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
  };

  loading = false;

  handleCancel(): void {
    this.$emit("close");
  }

  afterSubmitDate(curr: Moment): boolean {
    return useMinByDate(curr, moment(this.assetTransferResponseDTO.date));
  }

  receiveAssetTransfer(id: string, dto: ReceiveAssetTransferRequestDto): void {
    this.loading = true;
    assetTransferService
      .receiveAssetTransfer(id, dto)
      .then(res => {
        this.showNotifSuccess("notif_asset_transfer_receive_success", {
          documentNumber: res.documentNo,
        });
        this.$emit("success");
      })
      .finally(() => (this.loading = false));
  }

  validateForm(): void {
    this.formModel.validate(valid => {
      if (!valid) return;

      this.receiveAssetTransfer(
        this.assetTransferResponseDTO.id,
        this.formData
      );
    });
  }
}
