var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.$t("lbl_receive_asset_transfer"),
        visible: _vm.visible,
        width: "50%"
      },
      on: { cancel: _vm.handleCancel },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "a-space",
                [
                  _c("a-button", { on: { click: _vm.handleCancel } }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_close")) + " ")
                  ]),
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary", loading: _vm.loading },
                      on: { click: _vm.validateForm }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_receive")) + " ")]
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "a-form-model",
        {
          ref: "formModel",
          attrs: {
            rules: _vm.formRules,
            model: _vm.formData,
            "wrapper-col": { span: 18 },
            "label-col": { span: 6 },
            "label-align": "left"
          }
        },
        [
          _c(
            "a-form-model-item",
            {
              attrs: { label: _vm.$t("lbl_receive_date"), prop: "receiveDate" }
            },
            [
              _c("a-date-picker", {
                staticClass: "w-100",
                attrs: {
                  format: _vm.DEFAULT_DATE_FORMAT,
                  "disabled-date": _vm.afterSubmitDate
                },
                model: {
                  value: _vm.formData.receiveDate,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "receiveDate", $$v)
                  },
                  expression: "formData.receiveDate"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_receive_by"), prop: "receiveBy" } },
            [
              _c("a-input", {
                attrs: {
                  placeholder: _vm.$t("lbl_receive_by"),
                  "allow-clear": ""
                },
                model: {
                  value: _vm.formData.receiveBy,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "receiveBy", $$v)
                  },
                  expression: "formData.receiveBy"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }