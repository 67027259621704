






import Vue from "vue";

export default Vue.extend({
  name: "ListAccessories",
  props: ["items"],
  components: {
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },
  },
});
