import { Api } from "@/models/class/api.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import {
  AssetTransferResponseDto,
  CreateAssetTransferRequestDto,
  CreateAssetTransferResponseDto,
  ReceiveAssetTransferRequestDto,
} from "@/models/interface/asset-transfer";
import { BaseDocumentNumberResponseDto } from "@/models/interface/BaseDocumentNumberResponseDto.interface";
import { DownloadRequestParam } from "@/models/interface/download";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import { FileCreateResponseDto } from "@/models/interface/storage";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

class AssetTransferService extends HttpClient {
  createAssetTransfer(
    payload: CreateAssetTransferRequestDto
  ): Promise<CreateAssetTransferResponseDto> {
    return this.post<
      CreateAssetTransferResponseDto,
      CreateAssetTransferRequestDto
    >(Api.AssetTransfer, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  uploadAttachment(payload: FormData): Promise<FileCreateResponseDto> {
    return this.post<FileCreateResponseDto, FormData>(
      Api.AssetTransferAttachmentBucket,
      payload,
      { headers: { "Content-Type": "multipart/form-data" } }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  listAssetTransfers(
    params?: RequestQueryParamsModel
  ): Promise<Pagination<AssetTransferResponseDto>> {
    return this.get<Pagination<AssetTransferResponseDto>>(Api.AssetTransfer, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  downloadListAssetTransfers(params: DownloadRequestParam): Promise<Blob> {
    return this.get<Blob>(Api.AssetTransferDownload, {
      responseType: "blob",
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getAssetTransferDetail(id: string): Promise<AssetTransferResponseDto> {
    return this.get<AssetTransferResponseDto>(`${Api.AssetTransfer}/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  cancelAssetTranfer(id: string): Promise<BaseDocumentNumberResponseDto> {
    return this.put<BaseDocumentNumberResponseDto>(
      `${Api.CancelAssetTransfer}/${id}`
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  printAssetTransfer(id: string): Promise<Blob> {
    return this.get<Blob>(`${Api.PrintAssetTransfer}/${id}`, {
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  receiveAssetTransfer(
    id: string,
    payload: ReceiveAssetTransferRequestDto
  ): Promise<BaseDocumentNumberResponseDto> {
    return this.put<BaseDocumentNumberResponseDto>(
      `${Api.ReceiveAssetTransfer}/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const assetTransferService = new AssetTransferService();
